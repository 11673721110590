.App {
  /* text-align: center; */
  font-family: "Raleway rev=1";
}

.main {
  margin-top: 0px;
}

.ch-demo-header {
  min-height: inherit;
}

.sv-question {
  overflow: hidden;
}

.sv-container-modern {
  /* height: 700px; */
}

.sv-completedpage {
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;
  box-sizing: border-box;
}

.sv-completedpage:before {
  margin-left: 0px;
  padding: 0px 0px;
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("midia/img/imagem_aguardando.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.sv-thankyou::before {
  background-image: url("midia/img/bg_obrigado_verginia.png") !important;
}

.bem_vindo {
  width: 100%;
  color: rgba(57, 66, 65, 1);
  font-weight: 700;
  font-size: 48px;
  opacity: 1;
  text-align: left;
  margin-bottom: 45px;
}

.text_1 {
  width: 100%;
  color: rgba(57, 66, 65, 1);
  top: 515px;
  left: 170px;
  font-size: 18px;
  opacity: 0.6000000238418579;
  text-align: left;
  padding-bottom: 35px;
}

.text_2 {
  width: 100%;
  color: rgb(0, 0, 0);
  font-family: Noto Sans JP;
  position: absolute;
  font-size: 30px;
  opacity: 1;
  padding: 5px 10px;
  background-color: rgba(252, 250, 243, 1);
  bottom: 50px;
  text-align: center;
}

.corpo {
  display: flex;
  flex-flow: column;
}

.btn-border {
  padding: 7px 18px;
}

.text-page2 {
  float: none;
  margin: 0 auto;
  max-width: 900px;
  width: 60%;
  color: rgba(57, 66, 65, 1) !important
}

.cabecalho {
  background-color: rgba(252, 250, 243, 1);
  margin-top: 0px;
  display: flex !important;
  vertical-align: middle !important;
  text-align: center;
  justify-content: center;
  padding: 0px 0px;
  margin: 10px;
}

.cabecalho img {
  display: inline-block !important;
}

.cabecalho a {
  display: block;
  width: 700px;
  height: 70px;
}

.linha_1 {
  height: 100%;
}

.corpo_direita {
  height: 500px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0px;
}

.corpo_esquerdo {
  min-height: 24vw;
  position: relative;
}

.corpo_direita_img {
  width: 100%;
  height: 100%;
  background-image: url("midia/img/modelo_fem_fundo.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

body .modal-header .close {
  margin-top: -2px;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 45px;
  line-height: 30px;
}

body .modal-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  position: relative;
}

body .modal-footer {
  padding: 20px;
}

.text_termos {
  padding-bottom: 0;
  display: flex;
  flex-flow: row;
  margin-bottom: 0;
}

.text_termos .button_termos {
  display: flex;
  flex-flow: row;
  padding: 0;
  margin-right: 7px;
  align-items: center;
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  color: #68B3C8;
  cursor: pointer;
  font-size: 14px;
}

.group_termos {
  position: absolute;
  bottom: 0;
  left: 15px;
}

.text_termos .button_termos:hover,
.text_termos .button_termos:active,
.text_termos .button_termos:focus {
  background: transparent;
  color: #68B3C8;
  border-radius: 0;
  border-bottom: 1px solid #68B3C8;
}

.aceite {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.aceite .aceite_termo {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  margin-top: 0;
}

.aceite p {
  margin: 0;
  color: rgba(57, 66, 65, 1);
  font-size: 16px;
  font-weight: bold;
}

.sv-footer__next-btn.disabled {
  background: rgba(0, 0, 0, .5) !important;
}

.sv-root-modern .sv-footer__next-btn,
.sv-root-modern .sv-footer__prev-btn,
.sv-root-modern .sv-footer__complete-btn {
  background: #00939a;
}

.sv-root-modern .sv-progress__bar {
  background: #00939a;
}

.sv-root-modern .sv-question__title--answer {
  background: rgba(0, 147, 154, .3);
}

.text-obrigado {
  margin: 7px;
  display: block;
  position: relative;
  overflow: none;
  font-size: 25px;
}

.video-container-desktop {
  display: block;
}

.video-container-mobile {
  display: none;
}

@media screen and (max-width: 1350px) {

  .corpo_esquerdo {
    min-height: auto;
  }

  .group_termos {
    position: relative;
    left: 0;
  }
}

@media screen and (max-width: 991px) {
  .video-container-desktop {
    display: none;
  }

  .video-container-mobile {
    display: block;
    margin-bottom: 30px;
  }

  .cabecalho {
    margin-top: 0px;
  }

  .corpo_direita {
    height: 100%;
    margin-bottom: 30px;
  }


  .corpo_direita_img {
    width: 91vw;
    height: 60vw;
  }

  .text-page2 {
    width: 85% !important;
  }

  .text_1 {
    width: 100%;
    text-align: justify;
  }

  .linha_1 {
    height: inherit;
  }

  .text_2 {
    font-size: 20px;
  }

  .sv-completedpage {
    width: 91vw;
    /* height: 60vw; */
    margin-left: auto;
    margin-right: auto;
  }

  .sv-completedpage:before {
    width: 91vw;
    /* height: 60vw; */
  }

  .logo_img {
    width: 100%;
    height: 100%;
  }

  .bem_vindo {
    font-size: 50px;
  }

  .corpo_esquerdo {
    min-height: inherit;
  }

  .group_termos {
    left: 0;
    bottom: 0;
    position: relative;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .cabecalho {
    margin-top: 0px;
  }

  .text-page2 {
    width: 85%;
  }

}

@media(max-width: 479px) {
  .cabecalho {
    margin-top: 0px;
  }

  .text-page2 {
    max-width: 900px;
    width: 95%;
  }
}

.survey_creator {
  height: 100vh;
}


.centralizar-vertical-horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resize {
  resize: both;
  overflow: auto;
}

.max-w-480-px {
  max-width: 480px;
}

.min-w-480-px {
  min-width: 480px;
}

.svc-creator__banner {
  /* hidden: */
  display: none;
}

.svc-creator__area.svc-creator__area--with-banner {
  height: 100% !important;
}


.sv-title sv-container-modern__title {
  align-items: center;
}


.sv-logo__image {
  display: inline;
}

.bg-survey {
  height: 100%;
  min-height: 100vh;
  padding-left: 6vh;
  padding-right: 6vh;
}


.grid-rows-layout {
  grid-template-rows: 1fr auto;
}

.grid {
  display: grid;
}